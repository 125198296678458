<template>
  <v-dialog
    v-model="importDocumentsTemplate.dialog"
    max-width="600px"
    style="z-index: 99999999"
    persistent
  >
    <v-fade-transition mode="out-in">
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-file-arrow-up mr-2"></i>
          Import filled Manufacturer equipments template file
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="pt-5 pb-0"> </v-container>
          <v-container class="pt-5 pb-0">
            <v-file-input
              :ref="importDocumentsTemplate.fileInputRef"
              v-model="importDocumentsTemplate.file"
              :rules="importDocumentsTemplate.rules"
              accept=".xlsx"
              label="Drawing documents template file"
              outlined
              dense
              show-size
              counter
              truncate-length="46"
              :loading="importDocumentsTemplate.loading"
              :disabled="importDocumentsTemplate.loading"
            >
              <template v-slot:selection="{ text }">
                <v-chip color="info" dark small style="max-height: 18px; margin-top: 8px">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeImportTemplate()" :disabled="importDocumentsTemplate.loading">
            <i class="fal fa-times mr-2"></i> Cancel
          </v-btn>
          <v-btn
            color="info"
            @click="startImportingTemplate"
            :loading="importDocumentsTemplate.loading"
            :disabled="importDocumentsTemplate.loading || !templateValid"
          >
            <i class="fad fa-up-from-bracket mr-2"></i> Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-fade-transition>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios.js";
import equipmentService from "../../Equipments/services/equipments-service";
export default {
  name: "import-manufacturer-equipment",
  data() {
    return {
      importDocumentsTemplate: {
        dialog: false,
        fileInputRef: "import-template-file-input",
        loading: false,
        files: [],
        cancellation: null,
      },
    };
  },
  computed: {
    templateValid() {
      if (
        this.importDocumentsTemplate.file != null &&
        this.importDocumentsTemplate.file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
        return true;
      return false;
    },
  },
  methods: {
    open() {
      this.importDocumentsTemplate.dialog = true;
    },
    startImportingTemplate() {
      this.importDocumentsTemplate.loading = true;
      var formData = new FormData();
      formData.append(
        "formFile",
        this.importDocumentsTemplate.file,
        this.importDocumentsTemplate.file.name
      );
      const _cancelToken = axios.CancelToken;
      this.importDocumentsTemplate.cancellation = {
        cancelToken: _cancelToken,
        source: _cancelToken.source(),
      };
      formData.append("cancellation", this.importDocumentsTemplate.cancellation.source.token);
      equipmentService
        .ImportManufacturerEquipments(formData, {
          cancelToken: this.importDocumentsTemplate.cancellation.source.token,
        })
        .then((response) => {
          this.$log("Template is imported successfully!", response, response.data);
          this.$dialog.notify.success("Template is imported successfully!", {
            position: "top-right",
            timeout: 10 * 1000,
          });
          this.closeImportTemplate();
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$log("Uploading Template Error:", error);
          this.closeImportTemplate();

          if (axios.isCancel(error)) {
            this.$log("Importing template is cancelled by user!", error.message);
            this.$dialog.notify.error("Importing template is cancelled by user!", {
              position: "top-right",
              timeout: 10 * 1000,
            });
          } else {
            //handle error
            this.$handleError(error);
          }
        });
    },
    closeImportTemplate() {
      this.importDocumentsTemplate.dialog = false;
      this.importDocumentsTemplate.loading = false;
      this.importDocumentsTemplate.file = null;
    },
  },

  components: {},
};
</script>

<style></style>
