<template>
  <v-tooltip bottom nudge-bottom="-5">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-btn height="32" color="white" small @click="generate()">
          <i class="fad fa-file-spreadsheet" style="font-size: 16px"></i>
        </v-btn>
      </div>
    </template>
    <span>Download Excel</span>
  </v-tooltip>
</template>

<script>
import Enums from "../../../plugins/enums";
import equipmentService from "../services/equipments-service";
import { eventBus } from "../../../main";

export default {
  name: "export-excel",
  data() {
    return {
      Enums: Enums,
      isLoading: false,
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    generate() {
      this.$dialog
        .info({
          title: `Generate excel report?`,
          text: `Generate excel report from this equipemnt list?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Generate",
              color: "info",
              handle: () => {
                this.generateConfirmed();
              },
            },
          },
        })
        .then((res) => {});
    },
    generateConfirmed() {
      this.isLoading = true;
      this.$log("generateConfirmed >> this.options", this.options);
      const optionsToSend = this.$clean(this.options, true);
      equipmentService.exportExcel(optionsToSend).then(this.onResponse).catch(this.onCatch);
    },
    onResponse(resp) {
      this.$log("report generate resp.data", resp.data);
      this.$notify(resp.data, "info");
      eventBus.$emit("add-to-notification-center", resp.data);
      this.discardGenerate();
    },
    onCatch(err) {
      this.$log(">>> projectService export", err);
      this.isLoading = false;
      this.$dialog.notify.error(err, {
        position: "top-right",
        timeout: 3000,
      });
    },
    discardGenerate() {
      this.isLoading = false;
    },
    onSlideoutClosing() {
      this.discardGenerate();
    },
  },
  computed: {},
  watch: {},
  components: {},
};
</script>

<style lang="scss"></style>
