var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"comp-wrpr",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-autocomplete-alt',{staticStyle:{"width":"100%"},attrs:{"auto-select-first":"","rules":[_vm.allRules.required],"label":"Manufacturer","id":"equipmentManufacturer","placeholder":"Choose a Manufacturer","items":_vm.manufacturers,"filter":_vm.manufacturersFilter,"dense":"","filled":"","clearable":"","item-text":"name","item-value":"id","loading":_vm.isManufacturersLoading,"disabled":_vm.isManufacturersLoading},on:{"input":_vm.manufacturerChanged},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"company-logo-mini"},[(item.logoUrl != null && item.logoUrl != '')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":item.logoUrl,"height":"100%","width":"100%"}})]):_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":"/img/DNA_Symbol.png","height":"100%","width":"100%"}})])])])]}}],null,true)},[_c('span',[(item.logoUrl != null && item.logoUrl != '')?_c('v-img',{attrs:{"src":item.logoUrl,"height":"250px","width":"250px","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","height":"250px","width":"250px","contain":""}})],1)]),_c('h5',{staticClass:"ma-0 ml-2"},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"company-logo-mini"},[(item.logoUrl != null && item.logoUrl != '')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":item.logoUrl,"height":"100%","width":"100%"}})]):_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":"/img/DNA_Symbol.png","height":"100%","width":"100%"}})])])])]}}],null,true)},[_c('span',[(item.logoUrl != null && item.logoUrl != '')?_c('v-img',{attrs:{"src":item.logoUrl,"height":"250px","width":"250px","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","height":"250px","width":"250px","contain":""}})],1)]),_c('h5',{staticClass:"ma-0 ml-2"},[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.selected.manufacturerId),callback:function ($$v) {_vm.$set(_vm.selected, "manufacturerId", $$v)},expression:"selected.manufacturerId"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }