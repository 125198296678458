<template>
  <div>
    <v-dialog v-model="exportModal" max-width="500px" persistent>
      <v-card>
        <v-card-title class="font-weight-bold d-flex" small>
          <i class="fad fa-file-spreadsheet mr-2 mr-2"></i>Export Manufacturar Equipments
        </v-card-title>
        <v-divider></v-divider>
        <v-form>
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0">
                <manufacturer @manufacturerChanged="manufacturerChanged"></manufacturer>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeExportModal"><i class="fal fa-times mr-2"></i> Cancel</v-btn>
            <v-btn @click="exportFilledTemplate" color="info">
              <i class="fas fa-download mr-2"></i>Export
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="exportEquipmentsFilledTemplate.dialog"
      max-width="450px"
      style="z-index: 99999999"
      @click:outside="exportEquipmentsFilledTemplate.loading = true"
    >
      <v-fade-transition mode="out-in">
        <v-card v-if="exportEquipmentsFilledTemplate.loading" :key="'export-loading-card'">
          <v-card-text class="py-3">
            <h3 class="mb-2">Generating Filled Template...</h3>
            <v-progress-linear indeterminate color="info" class="mb-1" rounded></v-progress-linear>
          </v-card-text>
        </v-card>
        <v-card v-else :key="'export-result-card'">
          <v-card-title class="font-weight-bold" small>
            <i class="fad fa-file-arrow-down mr-2"></i>Exported filled template file is ready!
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container class="py-0">
              <div class="text-center py-7 mt-3 d-flex justify-center align-center flex-column">
                <div class="report-file-icon" :class="exportEquipmentsFilledTemplate.reportFileExt">
                  <i
                    class="fad fa-file-excel"
                    v-if="exportEquipmentsFilledTemplate.reportFileExt == 'xlsx'"
                  ></i>
                  <i
                    class="fad fa-file-word"
                    v-if="exportEquipmentsFilledTemplate.reportFileExt == 'docx'"
                  ></i>
                </div>
                <h3 class="report-result mt-2">Report Generated</h3>
                <h3 class="report-name mt-2 text--secondary">
                  {{ exportEquipmentsFilledTemplate.reportFileName }}
                </h3>
                <v-btn
                  text
                  color="info"
                  class="report-download-link mt-2"
                  :href="exportEquipmentsFilledTemplate.reportUrl"
                  :download="exportEquipmentsFilledTemplate.reportFileName"
                >
                  <i class="fad fa-arrow-alt-to-bottom mr-2"></i>Download
                </v-btn>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                exportEquipmentsFilledTemplate.dialog = false;
                exportEquipmentsFilledTemplate.loading = true;
              "
            >
              <i class="fal fa-xmark mr-2"></i> Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
    </v-dialog>
  </div>
</template>

<script>
import equipmentService from "../services/equipments-service";
import Manufacturer from "./Manufacturer.vue";

export default {
  name: "export-manufacturer-Equipment",
  data() {
    return {
      exportModal: false,
      exportEquipmentsFilledTemplate: {
        dialog: false,
        loading: false,
        reportUrl: "",
        reportFileName: "",
        reportFileExt: "",
      },
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    open() {
      this.exportModal = true;
    },
    closeExportModal() {
      this.exportModal = false;
    },
    exportFilledTemplate() {
      this.exportEquipmentsFilledTemplate.loading = true;
      setTimeout(() => {
        this.startExportingFilledTemplate();
      }, 50);
    },
    startExportingFilledTemplate() {
      this.closeExportModal();
      this.exportEquipmentsFilledTemplate.dialog = true;
      equipmentService
        .exportManufacturerFilledTemplate(this.manufacturer.id)
        .then((resp) => {
          this.exportEquipmentsFilledTemplate.dialog = true;
          this.exportEquipmentsFilledTemplate.loading = false;
          let url = URL.createObjectURL(
            new Blob([resp.data], {
              type: resp.data.type,
            })
          );
          this.exportEquipmentsFilledTemplate.reportUrl = url;
          this.exportEquipmentsFilledTemplate.reportFileName = resp.headers["x-file-name"];
          this.exportEquipmentsFilledTemplate.reportFileExt = resp.headers["x-file-name"]
            .split(".")
            .pop();
        })
        .catch((err) => {
          this.exportEquipmentsFilledTemplate.dialog = false;
          this.exportEquipmentsFilledTemplate.loading = true;
          this.$handleError(err);
        });
    },
    manufacturerChanged(val) {
      debugger;
      this.manufacturer = val;
    },
  },
  mounted() {},
  computed: {},
  watch: {},
  components: { Manufacturer },
};
</script>

<style lang="scss"></style>
