<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '800px'"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.fileManager.active"
    v-on:close="onSlideoutClosing"
    class="slideout-readonly-content"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Equipment Details ...
      </h3>
      <h3 v-else-if="equipment != null" class="font-weight-bold pa-1" small>
        <i class="fad fa-cabinet-filing mr-2"></i> Equipment <b>{{ equipment.tag }}</b
        >: Files
      </h3>
      <v-btn @click="discard()" :disabled="isFetching" icon><i class="far fa-times"></i></v-btn>
    </template>

    <v-scroll-y-transition mode="out-in">
      <v-container v-if="isFetching" class="pa-0 text-center" key="fetching">
        <video
          width="320"
          muted
          loop
          autoplay
          style="padding: 0.5rem; opacity: 0.64; margin: 0 auto"
        >
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Fetching Equipment Details ...</p>
      </v-container>
      <v-container v-else fluid key="results" class="pa-4">
        <div
          style="background: #eceff1; border-radius: 0.5rem; height: 100%"
          class="pa-5 inset-shadow"
        >
          <file-manager
            id="equipment-file-manager"
            ref="equipmentFileManager"
            label="Equipment Files"
            multiple
            :base-url="baseUrl"
          ></file-manager>
        </div>
      </v-container>
    </v-scroll-y-transition>
    <template v-slot:footer v-if="false">
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="discard" color="secondary" :disabled="isFetching">
          <i class="far fa-times mr-2"></i>Close
        </v-btn> -->
        <div class="d-flex ml-2" v-if="equipment != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                dark
                :loading="isFetching"
                :disabled="slideouts.fileManager.isLoading || isFetching"
                @click="getEquipmentFiles()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Equipment Files</span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import FileManager from "../../Shared/components/FileManager/FileManager.vue";
import ApiService from "../services/equipments-service.js";

export default {
  name: "equipment-file-manager",
  data() {
    return {
      baseUrl: null,
      equipmentId: null,
      equipment: null,
      isFetching: false,
      slideouts: {
        fileManager: {
          isLoading: false,
          active: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    discard() {
      this.slideouts.fileManager.active = false;
    },
    getEquipmentFiles() {
      this.isFetching = true;
      if (this.equipmentId) {
        ApiService.getEquipmentSummaryById(this.equipmentId)
          .then((resp) => {
            this.equipment = resp.data;
            this.isFetching = false;
          })
          .catch((err) => {
            this.isFetching = false;
            this.$handleError(err);
          });
      }
    },
    open(equipmentId) {
      this.equipmentId = equipmentId;
      this.equipment = null;
      this.baseUrl = `Equipments/${equipmentId}/Files`;
      this.getEquipmentFiles();
      this.slideouts.fileManager.active = true;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.fileManager.isLoading && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
  },
  watch: {},
  mounted() {
    this.getEquipmentFiles();
  },
  components: { FileManager },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}

// .old-equipment-container {
//   padding: 0.5rem;
//   border: 1px dashed rgba($shades-black, 0.4);
//   border-radius: 0.5rem;
// }
</style>
